<template>
  <div class="w-100 my-5">
    <div v-if="orderList">
      <div class="row" v-if="items.length > 0">
        <div v-for="(item, index) in items" :key="(item, index)">
          <div :class="`box-white p-0 d-flex flex-column ${isMobile ? 'mx-1 mt-5' : 'm-5'}`" style="width: 330px;">
            <div :id="`divOrderDetail_${index}`" class="position-relative">
              <div id="cardOrderDetail">
                <img class="m-5" :height="30" :src="assets.logoDark" />
                <div class="m-5 d-flex flex-column" v-if="item.eventInfo">
                  <span class="mt-5 font-bold font-18">{{ item.eventInfo.name }}</span>
                  <div class="mt-5 vertical-center">
                    <i class="fa fa-calendar-alt font-12 color-purple"></i>
                    <span class="ml-2 font-12 color-purple">{{ getDateStringFromTimestamp(item.eventInfo.startAt) }} - {{ getDateStringFromTimestamp(item.eventInfo.endAt) }}</span>
                  </div>
                  <div class="mt-5 vertical-center">
                    <i class="flaticon2-pin-1 font-12"></i>
                    <span class="ml-2 font-12">{{ item.eventInfo.address }}</span>
                  </div>
                </div>
                <div class="m-5 d-flex flex-column">
                  <span class="font-bold font-18">{{ item.ticketName }}</span>
                  <div class="mt-2 vertical-center">
                    <span class="font-bold font-18">No:</span>
                    <span class="ticket-number ml-2 font-bold font-18">{{ item.ticketNumber }}</span>
                  </div>
                  <VueQrcode class="mx-auto" :value="item.orderId" :options="qrOptions" level="H" />
                </div>
              </div>
              <img class="official-ticket" :src="assets.officialTicket" />
            </div>
            <v-btn class="ml-auto mr-5 mb-5" style="z-index: 10;" icon small @click="downloadOrderDetail(item, index)">
              <i class="flaticon-download font-bold font-25 color-purple"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="my-30 py-40 center" v-else>
        <p>{{ $t('no_data_found') }}</p>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>

<style scoped>
  .ticket-number {
    padding: 5px 15px;
    background: #D3D3D3 !important;
    border-radius: 5px !important;
  }
  .official-ticket {
    width: 130px;
    height: 130px;
    position: absolute;
    top: 0;
    right: 0;
  }
</style>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';
import html2canvas from 'html2canvas';

import { onSnapshot, query, collection, where } from 'firebase/firestore';
import { firestore } from '../../../main';
import { getDateStringFromTimestamp, toFileName, showLoading } from '../../../functions';

import logoDark from '@/assets/image/logo_dark.png';
import officialTicket from '@/assets/image/official_ticket.png';

export default {
  name: 'TicketList',
  components: {
    VueQrcode
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    userList() {
      return this.$store.state.userList || [];
    },
    eventList() {
      return this.$store.state.eventList || [];
    }
  },
  watch: {
    eventList() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        logoDark,
        officialTicket
      },
      qrOptions: {
        width: 280,
        color: {
          dark: '#170223',
          light: '#FFFFFF',
        }
      },
      visibleOrderDetailDialog: false,
      snapOrderList: null,
      orderList: null,
      items: []
    }
  },
  mounted() {
    this.getOrderListTask();
  },
  destroyed() {
    if (this.snapOrderList) {
      this.snapOrderList();
    }
  },
  methods: {
    getDateStringFromTimestamp,
    refreshData() {
      const items = [];
      this.orderList.forEach(orderInfo => {
        const buyerInfo = this.userList.find(element => element.userId === orderInfo.buyerId);
        orderInfo.buyerName = buyerInfo ? buyerInfo.userName : '';
        orderInfo.eventInfo = this.eventList.find(element => element.eventId === orderInfo.eventId);
        items.push(orderInfo);
      });
      this.items = items;
    },
    downloadOrderDetail(orderInfo, index) {
      const loader = showLoading(this, 1);
      const options = {
        type: 'dataURL'
      };
      html2canvas(document.getElementById(`divOrderDetail_${index}`), options).then(canvas => {
        const link = document.createElement('a');
        const fileName = `${orderInfo.buyerName}_${toFileName(orderInfo.ticketName)}_${orderInfo.ticketNumber}.jpg`;
        link.setAttribute('download', fileName);
        link.setAttribute('href', canvas.toDataURL('image/jpg').replace('image/jpg', 'image/octet-stream'));
        link.click();
        loader.hide();
      });
    },
    getOrderListTask() {
      if (this.snapOrderList) {
        return;
      }
      const q = query(collection(firestore, 'order'), where('transactionId', '==', this.$route.params.transactionId));
      this.snapOrderList = onSnapshot(q, querySnapshot => {
        const orderList = [];
        querySnapshot.forEach(doc => orderList.push(doc.data()));
        orderList.sort((a, b) => (b.ticketNumber < a.ticketNumber) ? 1 : -1);
        this.orderList = orderList;
        this.refreshData();
      });
    },
  }
};
</script>